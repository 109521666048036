@import url(./color-themes.css);

.soft-edges {
    border-radius: 7px;
}

.margin-h-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.margin-h-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.margin-h-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.margin-h-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.margin-h-60 {
    margin-left: 60px;
    margin-right: 60px;
}

.margin-v-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.margin-v-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-v-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-left-20 {
    margin-left: 20px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-20 {
    margin-right: 20px;
}

.padding-5 {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-v-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.padding-v-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-v-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-h-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.padding-h-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.lightgray-bold-border {
    border: 2px solid #eee;
}

.dark-bg {
    background-color: #171e27;
}

.lighter-dark-bg {
    background-color: #202a37;
}

.soft-shadow {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
}

@-webkit-keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.big-spinner,
.spinner {
    position: relative;
}

.big-spinner::after,
.spinner::after {
    content: '';
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 100%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: 10px;
    border: none;
    border-radius: 50%;
    border: 2px solid #4da1ff;
    border-top-color: #dbdbdb;
    -webkit-animation: spinner .6s linear infinite;
    animation: spinner .6s linear infinite;
}

.item-clickable {
    cursor: pointer;
}

.small-text {
    font-size: 14px;
}

.large-text {
    font-size: 22px;
}

.larger-text {
    font-size: 28px;
}

.text-center {
    text-align: center;
}

.justify-text {
    text-align: justify;
}

.gray-text {
    color: gray;
}

.lightgray-text {
    color: lightgray;
}

.white-text {
    color: white;
}

.error-text {
    color: #f82b60;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
}

.dark-bg {
    background-color: rgb(32, 42, 55);
}

.relative-element {
    position: relative;
}

.opacity-7 {
    opacity: .7;
}

.full-width {
    width: 100%;
}
.quote-text{
    font-style: italic;
}
.reference-text{
    opacity: .85;
    font-size: 12px;

}
.white-bg{
    background-color: white;
}
.padding-20{
    padding: 20px;
}
.soft-edges{
    border-radius: 5px;
}
.large-title{
    font-size: 22px;
}